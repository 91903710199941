const productModel = {
  id: undefined,
  url: undefined,
  image: { medium: undefined },
  name: undefined,
  title: undefined,
  price: undefined,
};
export const getDefaultProductList = itemPerRow => {
  const list = [];
  for (let i = 0; i < itemPerRow; i++) {
    list.push(productModel);
  }
  return list;
};

export const getPageInfo = (mediaContext, model) => {
  const { xs, sm, md, lg, xl } = mediaContext;
  const {
      productsOnExtraLargeScreen,
      productsOnLargeScreen,
      productsOnMediumScreen,
      productsOnSmallScreen,
      productsOnExtraSmallScreen,
  } = model;

  let itemPerRow = productsOnExtraLargeScreen;
  let itemColsize = null;
  if (xl) {
    itemPerRow = productsOnExtraLargeScreen;
    itemColsize = 100 / productsOnExtraLargeScreen;
  } else if (lg) {
    itemPerRow = productsOnLargeScreen;
    itemColsize = 100 / productsOnLargeScreen;
  } else if (md) {
    itemPerRow = productsOnMediumScreen;
    itemColsize = 100 / productsOnMediumScreen;
  } else if (sm) {
    itemPerRow = productsOnSmallScreen;
    itemColsize = 100 / productsOnSmallScreen;
  } else if (xs) {
    itemPerRow = productsOnExtraSmallScreen;
    itemColsize = 100 / productsOnExtraSmallScreen;
  }
  const pageSize = itemPerRow;
  return { pageSize, itemPerRow, itemColsize };
};